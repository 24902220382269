import axios, { AxiosResponse } from "axios";
import { Dictionary } from "../global/types";

export const getAllAccessCodes = async (params: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/admin/access-code/all-access-codes`;
    return axios.get(url, { params });
}

export const generateNewAccessCode = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/admin/access-code/generate`;
    return axios.get(url);
}

export const updateNotes = async (accessCodeId: string, notes: string) => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/admin/access-code/update/${accessCodeId}`;
    return axios.post(url, { notes });
}