import { makeAutoObservable, runInAction } from "mobx";
import { Store, IFeedStore, IPost, PostStatus, ProcessingStatus } from "../global/types";
import { approveFeedPost, getAllFeedsData, rejectFeedPost } from "../api/feed.action";
import { PENDING, PROCESSED } from "../utils/constant";

class FeedStore implements IFeedStore {
    rootStore: Store;
    allFeedData: IPost[] = [];
    error: string | null = null;
    loading: boolean = false;
    page: number = 1;
    searchTerm: string = '';
    status: PostStatus = PENDING;
    processingStatus: ProcessingStatus = PROCESSED;
    concertId: string = '';
    limit: number = 10;
    totalItems: number = 0;
    totalPages: number = 0;

    constructor(rootStore: Store) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
    }

    setLoading = (loading: boolean) => {
        this.loading = loading;
    }

    fetchAllFeedData = async (
        action: 'change_page' | 'search' | 'change_filter' | '',
        page: number,
        searchTerm: string,
        limit: number,
        status: PostStatus,
        processingStatus: ProcessingStatus,
        concert: string
    ) => {
        this.setLoading(true);
        try {
            runInAction(() => {
                switch (action) {
                    case 'change_page':
                        this.page = page;
                        break;
                    case 'search':
                        this.searchTerm = searchTerm;
                        this.page = 1;
                        break;
                    case 'change_filter':
                        this.limit = limit;
                        this.status = status;
                        this.concertId = concert;
                        this.processingStatus = processingStatus;
                        this.page = 1;
                        break;
                    default:
                        break;
                }
            })
            const data: any = {
                page: this.page,
                limit: this.limit,
                post_status: this.status,
                search: this.searchTerm?.trim(),
                processing_status: this.processingStatus,
            }
            if (this.concertId.trim() !== '') {
                data.concert_id = this.concertId.trim()
            }
            const response = await getAllFeedsData(data);
            runInAction(() => {
                this.allFeedData = response.data?.data?.feed_data || [];
                this.totalItems = response.data?.data?.totalItems;
                this.totalPages = response.data?.data?.totalPages;
            })
        } catch (error: unknown) {
            this.rootStore.authStore.handleUnauthorized(error);
        } finally {
            this.setLoading(false);
        }
    }

    approvePost = async (postId: string) => {
        try {
            this.setLoading(true);
            await approveFeedPost(postId);
            await this.fetchAllFeedData('', this.page, this.searchTerm, this.limit, this.status, this.processingStatus, this.concertId);
        } catch (error: unknown) {
            this.rootStore.authStore.handleUnauthorized(error);
        } finally {
            this.setLoading(false);
        }
    }

    rejectPost = async (postId: string) => {
        try {
            this.setLoading(true);
            await rejectFeedPost(postId);
            await this.fetchAllFeedData('', this.page, this.searchTerm, this.limit, this.status, this.processingStatus, this.concertId);
        } catch (error: unknown) {
            this.rootStore.authStore.handleUnauthorized(error);
        } finally {
            this.setLoading(false);
        }
    }

    resetFeedStore = () => {
        runInAction(() => {
            this.allFeedData = [];
            this.error = null;
            this.loading = false;
            this.page = 1;
            this.searchTerm = '';
            this.limit = 10;
            this.totalItems = 0;
            this.totalPages = 0;
        })
    }
}

export default FeedStore;
