import axios, { AxiosResponse } from "axios";
import { Dictionary } from "../global/types";

export const logIn = async (email: string, password: string): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/admin/login`;
    const body = { email, password };
    return axios.post(url, body);
};

export const logOut = async (): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/admin/logout`;
    return axios.post(url);
};

export const changePassword = async (data: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/admin/change-password`;
    return axios.post(url, data);
}

export const getAllBootlegData = async (data: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/admin/users/bootleg?page=${data.page}&limit=${data.limit}&search=${data.search}`;
    return axios.get(url);
}

export const getOneUserData = async (id: string): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/admin/user/details/${id}`;
    return axios.get(url);
}

export const updateBootlegData = async (data: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/admin/bootleg/edit`;
    return axios.post(url, data);
}

export const createBootleg = async (data: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/admin/bootleg/create`;
    return axios.post(url, data);
}

export const getAllArtistData = async (data: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/admin/users/artist?page=${data.page}&limit=${data.limit}&search=${data.search}`;
    return axios.get(url);
}

export const updateArtistData = async (data: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/admin/artist/edit`;
    return axios.post(url, data);
}

export const createArtist = async (data: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/admin/artist/create`;
    return axios.post(url, data);
}

export const getAllVenueData = async (data: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/admin/users/venue?page=${data.page}&limit=${data.limit}&search=${data.search}`;
    return axios.get(url);
}

export const updateVenueData = async (data: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/admin/venue/edit`;
    return axios.post(url, data);
}

export const createVenue = async (data: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/admin/venue/create`;
    return axios.post(url, data);
}

export const getAllPhotographerData = async (data: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/admin/users/photographer?page=${data.page}&limit=${data.limit}&search=${data.search}`;
    return axios.get(url);
}

export const updatePhotographerData = async (data: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/admin/photographer/edit`;
    return axios.post(url, data);
}

export const createPhotographer = async (data: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/admin/photographer/create`;
    return axios.post(url, data);
}

export const requestOtp = async (email: string): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/admin/forgot-password`;
    return axios.post(url, { email });
}

export const verifyOtp = async (data: {email: string, otp: string}): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/admin/verify-otp`;
    return axios.post(url, data);
}

export const resetPassword = async (data: {email: string, new_password: string}): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/admin/reset-password`;
    return axios.post(url, data);
}

export const getAllCustomersData = async (data: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/admin/users/user`;
    return axios.get(url, { params: data });
}

export const getUserProfile = async (): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/admin/profile`;
    return axios.get(url);
}

export const editUserProfile = async (name: string): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/admin/profile/edit`;
    return axios.post(url, { name });
}

export const getAllPricesForConcertData = async (): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/admin/transaction/concert-list`;
    return axios.get(url);
}

export const getPreSignedUrlImage = async (file_name: string, content_type: string): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/admin/files/generate-presigned-images`;
    const data = { file_name, content_type };
    return axios.post(url, data);
}

export const getPreSignedImageUrl = async (file_name: string, content_type: string, user_type: string): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/admin/files/generate-presigned-image`;
    const data = { file_name, content_type, user_type };
    return axios.post(url, data);
}

export const uploadFileToS3 = async (url: string, file: File, contentType: string): Promise<AxiosResponse> => {
    const axiosWithoutAuth = axios.create();
    delete axiosWithoutAuth.defaults.headers.common['Authorization'];
    
    return axiosWithoutAuth.put(url, file, {
        headers: {
            "Content-Type": contentType,
        },
    });
}

export const getAllCreditsData = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/admin/concert/get-credits-list`;
    return axios.get(url);
}

export const getCountryFromIp = async () : Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/v1/ip/get-country-details`; 
    return axios.get(url)
}
