const Loader = () => {
  return (
    <div className="fixed z-50 pointer-events-none top-0 left-0 right-0 bottom-0 flex justify-center items-center bg-[#cccccc69]">
      <div className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] ">
      </div>
    </div>
  )
};

const InlineLoader = () => {
  return (
    <div className="relative h-full w-full z-50 pointer-events-none flex items-center justify-center min-h-[50px] bg-[#cccccc2f]">
      <div className="h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] "></div>
    </div>
  );
};

export default Loader;
export { InlineLoader };

