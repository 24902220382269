import axios, { AxiosResponse } from "axios";
import { Dictionary } from "../global/types";

export const getAllTransactionHistoryData = async (data: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/admin/transaction/history-list`;
    return axios.get(url, { params: data });
}

export const getAllConcertsForTransactionHistoryData = async (): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/admin/transaction/concert-list`;
    return axios.get(url);
}